<template>
    <v-dialog v-model="dialog" width="600" scrollable persistent>
        <v-card>
            <v-card-title>
                Publicação de artigo
            </v-card-title>

            <v-card-text v-if="!open_site">
                <v-row class="pt-1">
                    <v-col cols="12">
                        <v-text-field v-model="title" outlined label="Título" hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field v-model="author" outlined label="Autor" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h4 class="mb-2">Imagem principal</h4>
                        <v-card height="100" :loading="uploading.main" max-width="180">
                            <v-img v-if="main_image" :src="main_image" height="100" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:75px;">mdi-image</v-icon>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="d-flex align-center text-center text-sm-left">
                        <div>
                            <v-btn small color="primary" class="mb-2 mb-sm-0 mr-sm-2" :loading="uploading.main" @click="pickFile('main')">
                                Alterar imagem
                            </v-btn>
                            <v-btn small color="error" :disabled="uploading.main" @click="removeImage('main')">
                                Remover imagem
                            </v-btn>

                            <div class="w-full mt-2">
                                Imagens permitidas: JPG, PNG e com tamanho máximo de 2MB.
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h4 class="mb-2">Imagem thumbnail</h4>
                        <v-card height="100" :loading="uploading.thumb" max-width="180">
                            <v-img v-if="thumb_image" :src="thumb_image" height="100" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:75px;">mdi-image</v-icon>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="d-flex align-center text-center text-sm-left">
                        <div>
                            <v-btn small color="primary" class="mb-2 mb-sm-0 mr-sm-2" :loading="uploading.thumb" @click="pickFile('thumb')">
                                Alterar imagem
                            </v-btn>
                            <v-btn small color="error" :disabled="uploading.thumb" @click="removeImage('thumb')">
                                Remover imagem
                            </v-btn>

                            <div class="w-full mt-2">
                                Imagens permitidas: JPG, PNG e com tamanho máximo de 2MB.
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-if="open_site">
                <v-row>
                    <v-col cols="12">
                        <v-card-title style="word-break: break-word;">Seu artigo foi cadastrado. Acesse o blog para visualizá-lo publicá-lo para todos os visitantes do site.</v-card-title>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDialog" v-if="open_site">Fechar</v-btn>
                <v-btn color="primary" text @click="openUrl" v-if="open_site">Acessar o Blog</v-btn>
                <v-btn color="error" text @click="closeDialog" v-if="!open_site">Cancelar</v-btn>
                <v-btn color="primary" text @click="publish" :loading="loading" v-if="!open_site">Publicar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';

export default {
    props: {
        value: Boolean,
        article: Object,
        content: String
    },
    data() {
        return {
            title: '',
            author: '',
            loading: false,
            uploading: { main: false, thumb: false },
            main_image: null,
            thumb_image: null,
            path: null,
            open_site: false,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) this.title = this.article.title;
                if (!val) this.clear();
            }
        }
    },
    async mounted() {

    },
    methods: {
        clear() {
            this.title = '';
            this.author = '';
            this.loading = false;
            this.uploading = { main: false, thumb: false };
            this.main_image = null;
            this.thumb_image = null;
            this.path = null;
            this.open_site = false;
        },

        openUrl() {
            window.open(this.path, "_blank");
        },

        closeDialog() {
            this.dialog = false;
        },

        async publish() {
            if (!this.title) return emitToastr("error", "Título do artigo necessário.");
            if (!this.author) return emitToastr("error", "Autor do artigo necessário.");

            const duda_article = {
                title: this.title,
                description: this.title,
                author: this.author,
                content: this.article.final_article,
                main_image: this.main_image || undefined,
                thumbnail: this.thumb_image || undefined
            }

            this.loading = true;
            const resp = await Api.publishArticle(this.article.user.duda_site_name, duda_article);
            this.loading = false;

            if (!resp.error && resp.message) {
                const url_path = this.createUrlPath(this.title);
                this.path = `https://www.futuremkt.digital/home/site/${this.article.user.duda_site_name}/blog/${url_path}`;
                this.open_site = true;
            }
        },

        createUrlPath(title) {
            const noAccent = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const path = noAccent
                .toLowerCase()              // Transformar tudo para minúsculas
                .replace(/\s+/g, "-")       // Substituir espaços por hífens
                .replace(/-+/g, "-")        // Remover múltiplos hífens consecutivos
                .replace(/^-+|-+$/g, "");   // Remover hífens no início ou no final da string

            return path;
        },

        async pickFile(target) {
            const input = document.createElement('input')

            input.type = 'file'
            input.accept = 'image/jpeg, image/png'

            input.onchange = (e) => {
                const file = e.target.files[0]

                this.upload(file, target)
            }

            input.click()
        },
        async upload(file, target) {
            this.uploading[target] = true;

            const response = await this.$api.uploadImage(file);

            if (response.error) {
                this.uploading[target] = false;
                this.$toast('error', response.message);
                return
            }

            if (target == 'main') this.main_image = response.message;
            if (target == 'thumb') this.thumb_image = response.message;

            setTimeout(() => {
                this.uploading[target] = false;
            }, 800);
        },

        removeImage(target) {
            if (target == 'main') this.main_image = null;
            if (target == 'thumb') this.thumb_image = null;

        }
    }
};
</script>

<style></style>